<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="section_1 flex-col">
     
        <div class="group_2 flex-row justify-between">
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/58655ba26518028a498ca0af77f9a241.png"
          />
          <span class="text_5">提现</span>
        </div>
        <div class="group_3 flex-col">
          <div class="list_1 flex-row">
            <div class="text-wrapper_2 flex-col justify-between" v-for="(item, index) in loopData0" :key="index">
              <span class="text_6" v-html="item.lanhutext0"></span>
              <span class="text_7" v-html="item.lanhutext1"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="section_2 flex-col">
        <span class="text_8">收益记录</span>
        <div class="group_4 flex-row">
          <div class="box_2 flex-col"></div>
          <div class="text-wrapper_3 flex-col justify-between">
            <span class="text_9">13659874521</span>
            <span class="text_10">2024.05.16&nbsp;15:36:25</span>
          </div>
          <div class="text-wrapper_4">
            <span class="text_11">美食积分</span>
            <span class="text_12">+10</span>
          </div>
        </div>
        <div class="group_5 flex-col"></div>
        <div class="group_6 flex-row">
          <div class="image-wrapper_1 flex-col">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/1d888737f3df1a8d1ecf41118a5ebe30.png"
            />
          </div>
          <div class="text-wrapper_5 flex-col justify-between">
            <span class="text_13">13659874234</span>
            <span class="text_14">2024.05.16&nbsp;15:36:25</span>
          </div>
          <div class="text-wrapper_6">
            <span class="text_15">美食积分</span>
            <span class="text_16">+10</span>
          </div>
        </div>
        <div class="group_7 flex-col"></div>
        <div class="group_8 flex-row">
          <div class="image-wrapper_2 flex-col">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/a89afa52380057d92038b2d443122dc5.png"
            />
          </div>
          <div class="text-wrapper_7 flex-col justify-between">
            <span class="text_17">13549874234</span>
            <span class="text_18">2024.05.16&nbsp;15:36:25</span>
          </div>
          <div class="text-wrapper_8">
            <span class="text_19">美食积分</span>
            <span class="text_20">+10</span>
          </div>
        </div>
        <div class="group_9 flex-col"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        { lanhutext0: '169', lanhutext1: '养生积分' },
        { lanhutext0: '169', lanhutext1: '美食积分' },
        { lanhutext0: '10', lanhutext1: '个人分享' },
        { lanhutext0: '2', lanhutext1: '团队分享' }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />